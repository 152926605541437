<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row no-gutters>
      <v-col>
        <!-- ---------------------------------------------------------------------------------------------------------- ->
        <! search results                                                                                               ->
        <!- ------------------------------------------------------------------------------------------------------------->
        <v-row justify="center" class="ma-4">
          <v-col xs="12" sm="12" md="12">
            <v-card class="elevation-6">
              <w-entity-list
                :columns="flowsColumns"
                :items="taskFlows"
                :read-path="readPath"
                read-ref="reference"
                title="Running Task Flows"
                sort-by="createdAt,desc"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { VCard, VCol, VContainer, VRow } from "vuetify/lib";
import WEntityList from "../../components/external/modules/shared/views/WEntityList.vue";
import api from "../../components/external/modules/shared/utils/api";
import path from "../../shared/paths";
import datehourformat from "../../components/external/modules/shared/utils/datehourformat";

export default {
  components: {
    VCard,
    VCol,
    VContainer,
    VRow,
    WEntityList,
  },

  data: () => ({
    taskFlows: [],
    flowsColumns: [
      { value: "createdAt", text: "created" },
      { value: "reference", text: "reference" },
      { value: "customReference", text: "cust. ref." },
      { value: "statusString", text: "status" },
      { value: "started", text: "started" },
      { value: "finished", text: "finished" },
    ],
    readPath: path.TASKFLOW,
  }),

  watch: {},

  created() {
    this.search();
  },

  methods: {
    search() {
      this.taskFlows = []; // cleaning the taskFlows

      // performing the search
      api
        .get("taskflow/list/-1")
        .then((res) => {
          if (res.data.status === 200 && res.data.entities !== null) {
            this.taskFlows = res.data.entities;
            this.taskFlows.forEach((flow) => {
              flow.createdAt = datehourformat(flow._creation);
            });
          } else {
            console.log(new Error("Failed searching the flows"));
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>
